/**
 * This is the main entry point script for EasyXDI.
 *
 */
import "./polyfill/polyfills"

import React from "react"
import ReactDOM from "react-dom"
import { observer } from "mobx-react"
import { BrowserRouter } from 'react-router-dom'

import ReactGA from "react-ga"

import accountManager from "../CR-Components/CoreFiles/account"
import { pushLocationHashParams } from "../CR-Components/CoreFiles/history"
import appState from "../CR-Components/CoreFiles/state"
import { logToLumberjack } from "../CR-Components/lumberjack.js"
import App from "./App/App.jsx"
import { shareAnalysis } from "../CR-Components/CoreFiles/analyses"

import "normalize.css/normalize.css"

//Google analytics for react
ReactGA.initialize("UA-148155584-1")

let loggerAlertActive = false

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

if (!crConstants.disableGlobalErrorCatching) {
    console.log("Error catching is ON")
    function alertAndLogError(message, data) {
        logToLumberjack({
            message,
            data,
            user: appState.account ? appState.account.username : null,
        })
        if (loggerAlertActive) {
            alert(
                "Unfortunately a problem occured. We've logged the error, " +
                    "and will try to fix it as soon as possible.\n\n" +
                    "Please try again by refreshing this page in a few minutes.\n\n" +
                    `Details:\n ${message}\n ${JSON.stringify(data, null, 2)}`
            )
        }
        console.log("Caught and logged error:", data)
    }

    /* Capture logged errors and send them to Lumberjack */
    (function () {
        console.oldError = console.error
        console.error = function (...args) {
            alertAndLogError("Error logged to console", args)
        }
    })()

    /* Capture logged errors and send them to Lumberjack */
    window.onerror = function (msg, url, line, col, error) {
        let args = { msg: msg, url: url, line: line, col: col, error: error }
        alertAndLogError("Uncaught error", args)
    }
    window.addEventListener("error", function (event) {
        alertAndLogError("Uncaught error (listener)", event)
    })
}

const loadWithRetry = async (funcDef) => {
    let success = false,
        exception
    for (let attemptCount = 0; attemptCount < 3 && !success; attemptCount++) {
        try {
            await funcDef.func()
            success = true
        } catch (ex) {
            exception = ex
            console.log(ex)
            const sleepTime = Math.pow(attemptCount + 1, 2)
            console.log(`Retrying ${funcDef.name} in ${sleepTime} seconds.`)
            await sleep(sleepTime * 1000)
        }
    }
    return exception
}

const load = async () => {
    loggerAlertActive = false
    const getParams = new URLSearchParams(window.location.search)

    const loadFuncs = [
        {
            func: async () => {
                if (getParams.get("crapikey")) {
                    await accountManager.loginWithApiKey(getParams.get("crapikey"))
                    pushLocationHashParams({ showForm: true })
                } else if (!getParams.get("pwResetToken")) {
                    await accountManager.doInitialLogin()
                }
            },
            name: "authentication",
        },
        {
            func: async () => {
                const materials = await jQuery.ajax({
                    url: `${crConstants.acrobaseHost}/v1/materials?expand_aliases=true`,
                });

                appState.materials = Object.values(materials)
                    .map(({ failure_coef, ...data }) => ({
                        ...data,
                        ...failure_coef,
                    }))
                    .reduce((obj, m) => {
                        return { ...obj, [m.id]: m };
                    }, {});
            },
            name: "load material data",
        },
    ]

    let success = true
    for (const funcDef of loadFuncs) {
        try {
            const exception = await loadWithRetry(funcDef)
            if (!!exception) {
                success = false
                break
            }
        } catch (ex) {
            if (!crConstants.disableGlobalErrorCatching) {
                alertAndLogError(`Error performing ${funcDef.name}.`, { exception: ex })
            } else {
                throw ex
            }
        }
    }

    appState.loading = !success

    //logic to show verify email modal
    var url = window.location.search
    var queryString = url ? url.split("?")[1] : window.location.search.slice(1)
    var urlParameters = queryString.split("=")
    if (
        (urlParameters[1] === true || urlParameters[1] === "true") &&
        urlParameters[0] === "verifyEmail"
    ) {
        appState.verifyEmail = true
    } else if (urlParameters[0] === "analysisId") {
        appState.shareAnalysis = urlParameters[1]
        await fetchShareAnalysis(urlParameters[1])
    }
}

const fetchShareAnalysis = async (analysisId) => {
    return shareAnalysis(analysisId)
}

load()


@observer
class StateObserver extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <App
                    verifyEmail={this.props.appState.verifyEmail}
                    shareAnalysis={this.props.appState.shareAnalysis}
                    loading={this.props.appState.loading}
                    account={this.props.appState.account}
                    analyses={this.props.appState.analyses}
                />
            </BrowserRouter>
        )
    }
}
ReactDOM.render(<StateObserver appState={appState} />, document.getElementById("react-root"))
