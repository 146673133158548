import React from "react";
import autoBind from "react-autobind";
import { Button, Modal, Nav, Tab, Tabs } from "react-bootstrap";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import Dialog from "react-bootstrap-dialog";
import ReactGA from "react-ga";
import PropTypes from 'prop-types'
import csvExport from './csvExport'

import { hubSpotTracking } from "../../CR-Components/hubSpot.js";
import appState from "../../CR-Components/CoreFiles/state";
import accountManager from "../../CR-Components/CoreFiles/account";
import {
    CR_doAnalysis,
    saveAnalysis,
    saveSearch,
    handleDeleteAdaptation,
    handleSaveAdaptation,
} from "../../CR-Components/CoreFiles/analyses.js";
import { getAnalysisRevisionTitle, runChangedParameters } from '../../CR-Components/analyses'
import ModalLoginForm from "../../CR-Components/Account/ModalLoginForm.jsx";
import PasswordResetModal from "../../CR-Components/Account/PasswordResetModal.jsx";
import SaveResult from "../../CR-Components/SaveResult/SaveResult.jsx";
import Feedback from "../../CR-Components/Feedback/Feedback.jsx";
import AssetCustomisation from "../../CR-Components/AssetCustomisation/AssetCustomisation.jsx";
import NiceSelect from "../../CR-Components/UI-Utils/NiceSelect.jsx";
import EditInline from "../EditInline/EditInline.jsx";
import Loading from "../../CR-Components/Loading.jsx";
import Detail from "./Detail.jsx";
import Ratings from "./Ratings.jsx";
import ValueAtRisk from "../../CR-Components/Results/ValueAtRisk.jsx";
import HazardBreakdown from "../../CR-Components/Results/HazardBreakdown.jsx";
import Vulnerability from "../../CR-Components/Results/Vulnerability.jsx";
import ThankYouPurchase from "../../CR-Components/ThankYouPurchase/ThankYouPurchase.jsx";
import { parseHashParams, replaceLocationHashParams } from "../../CR-Components/CoreFiles/history";
import StreetView from "../../CR-Components/Results/LocationView.jsx";
// import CopyLinkModal from "../CopyLinkModal/CopyLinkModal.jsx";
import AdaptationWrapper from "../../CR-Components/Adaptation/AdaptationWrapper.jsx";
import PDFReport from "../../CR-Components/Reports/PdfReportComponents/PDFReport.jsx";
import * as moment from "moment";

import "./AnalysisResult.scss";

const TAB_LIST = [
    { key: "ratingsTab", title: "Ratings" },
    { key: "detailTab", title: "Detail" },
    { key: "settingsTab", title: "Settings" },
    { key: "locationTab", title: "Location" },
    { key: "varTab", title: "Value At Risk" },
    { key: "breakdownTab", title: "Breakdown" },
    { key: "vulnerabilityTab", title: "Vulnerability" },
    { key: "adaptationsTab", title: "Adaptations" },
    { key: "sampleReportTab", title: "View a Sample Report" },
];

@observer
class AnalysisResult extends React.Component {
    static propTypes = {
        account: PropTypes.object,
        analysis: PropTypes.object.isRequired,
        fieldDefaultValues: PropTypes.object.isRequired,
        analysisGroup: PropTypes.array,
        defaultParams: PropTypes.object,
        handleClose: PropTypes.func,
        onCopyLink: PropTypes.func,
        onNewAnalysis: PropTypes.func,
        onPurchase: PropTypes.func,
        onSaveAnalysis: PropTypes.func,
        onSwitchAnalysis: PropTypes.func,
        CV: PropTypes.bool
    }

    static defaultProps = {
        CV: false
    }

    constructor(props) {
        super(props);
        this.state = {
            loginRequiredForPurchase: false,
            addPassword: false,
            loading: false,
            showPurchaseModal: false,
            thanks: false,
            loadingReport: false,
            showReportProcessingModal: false,
            filename: null,
            selectedTab: "ratingsTab",
            exportError: false,
            reportDownloadError: false,
            isPurchased: false,
            canExport: false,
        };
        autoBind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.loginRequiredForPurchase && nextProps.account && !this.props.account) {
            this.setState({ loginRequiredForPurchase: false });
            this.props.onPurchase();
        }
    }
    updateAccount() {
        this.setState({ addPassword: false });
        this.props.onPurchase();
    }
    // checks if account has a password

    handlePurchase() {
        const { onPurchase, account } = this.props;
        if (account && this.props.account.password) {
            onPurchase();
        } else if (account && !this.props.account.password) {
            this.setState({ addPassword: true });
        } else {
            this.setState({ loginRequiredForPurchase: true });
        }
    }

    async handleDownload() {
        const type = "DOWNLOAD_REPORT"
        const { analysis, account: {username} } = this.props
        const { name, address } = analysis.properties
        const asset = {
            "_id": analysis._id,
            geometry: analysis.geometry,
            archetype: analysis.inputs.archetype.acronym
        }

        var illegalRe = /[\/\?<>\\:\*\|":]/g;
        var controlRe = /[\x00-\x1f\x80-\x9f]/g;
        var reservedRe = /^\.+$/;
        var windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
        var windowsTrailingRe = /[\. ]+$/;

        const filename = `${analysis.properties.address}.pdf`
            .replace(illegalRe, "_")
            .replace(controlRe, "_")
            .replace(reservedRe, "_")
            .replace(windowsReservedRe, "_")
            .replace(windowsTrailingRe, "_");

        try {
            const response = await this.logToBlackhole({ type, username, name, address, asset })
            if(response.status === 200) {
                this.setState({ showReportProcessingModal: true, filename: filename });
            } else {
                this.setState({ reportDownloadError: true })
                const responseJson = await response.json()
                console.error(responseJson)
            }
        } catch (error) {
            this.setState({ reportDownloadError: true })
            console.error(error)
        }
    }

    onDownload() {
        if (crConstants.production) ReactGA.modalview("/analysis-result/download-report")
        this.setState({ showReportProcessingModal: false });
    }

    async checkWhitelist(body) {
        let hasAccess = false;

        const res = await fetch("https://easyxdi.com/allowlist", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        })
            .then((r) => r.text())
            .catch(() => {
                hasAccess = false;
                console.log("allowlist check error");
            });

        hasAccess = res === "Authorised"

        return hasAccess
    }

    async handleExport() {
        const type = "EXPORT_CSV"
        const { analysis, account: {username} } = this.props;
        const { name, address } = analysis.properties
        const asset = {
            "_id": analysis._id,
            geometry: analysis.geometry,
            archetype: analysis.inputs.archetype.acronym
        }

        const csv = csvExport(analysis._id, analysis.properties, analysis.inputs.scenario)
        const csvString = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`
        const link = document.createElement("a");
        link.href = csvString;
        link.download = "export.csv";

        try {
            const response = await this.logToBlackhole({ type, username, name, address, asset })
            if(response.status === 200) {
                link.click();
            } else {
                this.setState({ exportError: true })
                const responseJson = await response.json()
                console.error(responseJson)
            }
        } catch (error) {
            this.setState({ exportError: true })
            console.error(error)
        }
    }

    async logToBlackhole(body) {
        return await fetch(crConstants.blackhole, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                dateCreated: moment().format('YYYY-MM-DD H:mm:ss'),
                ...body,
                origin: crConstants.domain
            })
        })
    }

    getFloodDefenceMessage() {
        const { analysis } = this.props;
        // TODO: check the logic here - flood defence data should always be available,
        // I think, but seems not to be.
        let flood_defence_message = "";
        if (
            "flood_riverine" in analysis.metadata &&
            analysis.metadata.flood_riverine.flood_defence_return_period > 0
        ) {
            flood_defence_message =
                `This asset is situated in a flood defended area, ` +
                `and is protected against 1 in ${analysis.metadata.flood_riverine.flood_defence_return_period} year floods.`;
        } else {
            flood_defence_message = `This asset is not situated in a flood defended area.`;
        }
        return flood_defence_message;
    }

    // TODO: This also exists in CR-Components::AdaptationWrapper - merge?
    // This is used for AdaptationCustomisation only
    async EASY_runChangedParameters(parameters, elements) {
        // see https://gitlab.com/climaterisk/easyxdi/-/issues/388

        if (crConstants.production) {
            ReactGA.modalview("/analysis-result/asset-customisation/run-changes")
        }

        this.setState({ loading: true });
        const analysis = await CR_doAnalysis({
            parameters: {
                ...this.props.fieldDefaultValues,
                ...parameters,
            },
            elements,
        });

        // TODO: Migrate processAnalysis logic here, if necessary. Ideally
        // remove everything that's not part of the Ana return, and modify Easy
        // internals to use Ana-formatted analysis by default.

        analysis.groupId = this.props.analysis.groupId;
        if (this.props.analysis.payment) {
            analysis.payment = this.props.analysis.payment;
        }

        this.setState({ analysis, loading: false });
        this.props.onNewAnalysis(analysis);

        // This is used to save searches to auth. records only
        try {
            var userName = "";
            if (this.props.account) {
                userName = this.props.account.username;
            }

            const params = {
                ...this.props.fieldDefaultValues,
                ...parameters,
                username: userName,
            };
            // save search parameters to auth provider
            saveSearch(params);
        } catch {
            console.log("Log search failed");
        }

        // used for testing
        return analysis
    }

    handleSwitchAnalysis(analysisId) {
        // TODO: this was used for adaptations to change analyses. Must remove
        this.setState({
            valueUsedToTriggerChildAgain: 1,
        });

        // if this analysis is unsaved.
        if (!this.props.analysis._id) {
            const analysisIndex = this.props.analysisGroup.findIndex((a) => a._id == analysisId);
            // Trigger prompt for user.
            this.confirmSwitchToAnalysisDialog.show({
                body: (
                    <React.Fragment>
                        <h4>Warning</h4>
                        <p>
                            The current analysis is not saved, are you sure you want to discard it?
                        </p>
                    </React.Fragment>
                ),
                actions: [
                    Dialog.CancelAction(),
                    Dialog.Action(
                        <span className="confirm-switch">
                            Discard current analysis and switch to{" "}
                            <em>
                                {getAnalysisRevisionTitle(
                                    this.props.analysisGroup[analysisIndex],
                                    analysisIndex,
                                    this.props.analysisGroup.length
                                )}
                            </em>
                        </span>,
                        () => this.props.onSwitchAnalysis(analysisId),
                        "btn-info"
                    ),
                ],
                bsSize: "large",
            });
        } else {
            this.props.onSwitchAnalysis(analysisId);
        }
    }

    handleCheckThanks() {
        const { thanks } = parseHashParams({ thanks });
        if (thanks) {
            return true;
        }
    }

    /**
     *
     * @param {object} analysis
     */
    async handleSaveCustom(analysis) {
        analysis = await saveAnalysis(analysis);
        this.props.onSwitchAnalysis(analysis._id);
    }

    // This function takes a string input of the tab name
    // and changes it to true in selectedTab and all others
    // to false
    handleChangeTab(selectedName) {
        let tabs = this.state.selectedTab;
        Object.keys(tabs).forEach((v) => (tabs[v] = false));
        tabs[selectedName] = true;
        this.setState({ selectedTab: tabs });
    }

    handleTabSelect(key) {
        this.setState({ selectedTab: key });
    }

    handleModalEntered() {
        if (crConstants.production) ReactGA.modalview("/analysis-result");
        window.document.title = "Analysis Result";

        hubSpotTracking("setPath", "/analysis-result", "trackPageView");

        if (window.location.hash) {
            hubSpotTracking("setPath", `/${window.location.hash}`, "trackPageView");
        }
    }

    async handleCloseThanks() {
        replaceLocationHashParams({ thanks: false });
    }

    async componentDidMount() {
        const { analysis, account } = this.props;

        const isPurchased = await accountManager.isPurchased(analysis, account);
        const canExport = await accountManager.canExport(analysis, account);

        this.setState({ isPurchased, canExport })
    }

    render() {
        const {
            analysis,
            fieldDefaultValues,
            account,
            handleClose,
            onSaveAnalysis,
            analysisGroup,
            onCopyLink,
            CV,
        } = this.props;
        const {
            loginRequiredForPurchase,
            addPassword,
            loading,
            showReportProcessingModal,
            filename,
            selectedTab,
            exportError,
            reportDownloadError,
        } = this.state;

        const viewportWidth = appState.viewportWidth;

        const thanks = this.handleCheckThanks();

        const flood_defence_message = this.getFloodDefenceMessage();

        const headerFooterButtons = (
            <div className="header-footer-buttons">
                {!!analysis._id && (
                    <React.Fragment>
                        <a
                            onClick={onCopyLink}
                            className={`share btn-secondary ${
                                appState.viewportWidth >= 1070 ? "" : "btn-sm"
                            }`}
                        >
                            <i className="material-icons">share</i>
                        </a>
                    </React.Fragment>
                )}

                {!this.state.isPurchased && !loginRequiredForPurchase && (
                    <Button className="btn-primary btn-sm" onClick={this.handlePurchase}>
                        Purchase commercial
                    </Button>
                )}

                {loginRequiredForPurchase && (
                    <ModalLoginForm
                        onCancel={() => {
                            this.setState({ loginRequiredForPurchase: false });
                            window.document.title = "EasyXDI";
                        }}
                        showRegister={true}
                        noPassword={false}
                    />
                )}

                {addPassword && (
                    <PasswordResetModal
                        username={account.username}
                        addCheck={true}
                        onCancel={() => this.setState({ addPassword: false })}
                        onDone={() => this.updateAccount()}
                    />
                )}

                {thanks && this.state.isPurchased && (
                    <ThankYouPurchase onCancel={() => this.handleCloseThanks()} />
                )}

                <SaveResult analysis={analysis} onSubmit={onSaveAnalysis} />

                <Feedback buttonText="Strange result?" analysis={analysis} />

                {this.state.canExport && this.state.isPurchased && (
                    <Button
                        variant="secondary"
                        onClick={this.handleExport}
                        title="Export"
                        disabled={exportError}
                    >
                        {exportError ? "Error: contact XDI" : "Export"}
                    </Button>
                )}
                
                {this.state.isPurchased && (
                    <Button
                        variant="secondary"
                        onClick={this.handleDownload}
                        title="Download this report"
                        disabled={reportDownloadError}
                    >
                        {reportDownloadError ? "Error: contact XDI" : "Download PDF Report"}
                    </Button>
                )}
            </div>
        );

        if (CV) return null

        const validTabList = TAB_LIST.reduce((list, tab) => {
            switch (tab.key) {
            case "settingsTab":
                if (!fieldDefaultValues) {
                    return list;
                }
                break;

            case "adaptationsTab":
                if (!this.state.isPurchased || !fieldDefaultValues) {
                    return list;
                }
                break;
            }

            return [...list, tab];
        }, []);

        return (
            <React.Fragment>
                {showReportProcessingModal && (
                    <PDFReport
                        isPurchased={this.state.isPurchased}
                        analysisGroup={analysisGroup}
                        analysis={analysis}
                        fieldDefaultValues={appState.fieldDefaultValues}
                        account={account}
                        onDownload={this.onDownload}
                        filename={filename}
                        materials={appState.materials}
                    />
                )}

                {!showReportProcessingModal && <Modal
                    show={true}
                    onHide={handleClose}
                    onEntered={() => this.handleModalEntered()}
                    className="AnalysisResult"
                >
                    <Modal.Header closeButton>
                        <div>
                            <Modal.Title>{analysis.properties.address || analysisGroup[0].properties.address}</Modal.Title>
                            <div>{analysis.inputs.archetype.name}</div>
                        </div>

                        {/* If the analysis is a revision allow the title to be edited */}
                        {analysisGroup.length > 0 && (
                            <EditInline
                                doc={analysis}
                                field="title"
                                updateFunc={saveAnalysis}
                                inputType={EditInline.types.textfield}
                                disabled={!analysis._id}
                            >
                                {analysisGroup.length + (analysis._id ? 0 : 1) < 2 ? (
                                    getAnalysisRevisionTitle(analysis, 0, analysisGroup.length)
                                ) : (
                                    <NiceSelect
                                        options={analysisGroup.map((a, i) => {
                                            return {
                                                value: a._id,
                                                label: getAnalysisRevisionTitle(
                                                    a,
                                                    i,
                                                    analysisGroup.length
                                                ),
                                            };
                                        })}
                                        value={analysis._id}
                                        onChange={this.handleSwitchAnalysis}
                                        placeholder="<Unsaved analysis>"
                                    />
                                )}
                            </EditInline>
                        )}

                        {viewportWidth > 799 && headerFooterButtons}
                    </Modal.Header>

                    <Modal.Body>
                        <Tab.Container mountOnEnter={true} activeKey={selectedTab} onSelect={this.handleTabSelect}>
                            <Nav className="desktop-tabs" variant="tabs">
                                {validTabList.map(({ key, title }) => (
                                    <Nav.Item as={Nav.Link} eventKey={key} key={key}>
                                        { title }
                                    </Nav.Item>
                                ))}
                            </Nav>
                            <select
                                className="mobile-tabs"
                                onChange={(event) => this.handleTabSelect(event.target.value)}
                                value={selectedTab}
                            >
                                {validTabList.map(({ key, title}) => (
                                    <option key={key} value={key}>{ title }</option>
                                ))}
                            </select>
                            <Tab.Content>
                                <Tab.Pane eventKey="ratingsTab">
                                    <div
                                        className="tab-pane fade show active ratings"
                                        id="ratings"
                                        role="tabpanel"
                                        aria-labelledby="ratings-tab"
                                    >
                                        <Ratings
                                            analysis={analysis}
                                            fieldDefaultValues={fieldDefaultValues}
                                        />
                                    </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="detailTab">
                                    <div
                                        className="tab-pane fade show detail"
                                        id="detail"
                                        role="tabpanel"
                                        aria-labelledby="detail-tab"
                                    >
                                        <Detail
                                            analysis={analysis} />
                                    </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="settingsTab">
                                    <div
                                        className="tab-pane fade show settings"
                                        id="settings"
                                        role="tabpanel"
                                        aria-labelledby="settings-tab"
                                    >
                                        {/* Warns how many revisions remain with purchase */}
                                        {analysisGroup.length < 3 && (
                                            <div className="purchase-commercial-inline">
                                                This tab allows customisation of the individual
                                                asset. There is a limit of two revisions per
                                                purchase, update as many elements as needed before
                                                clicking &quot;Update Analysis&quot;. You have{" "}
                                                {analysisGroup.length == 1 ? "TWO" : "ONE"}{" "}
                                                revisions remaining <br />
                                            </div>
                                        )}

                                        {/* Allowed revisions exceeded */}
                                        {analysisGroup.length >= 3 && (
                                            <div className="purchase-commercial-inline">
                                                Maximum number of analysis revisions reached. <br />
                                            </div>
                                        )}

                                        <AssetCustomisation
                                            viewportWidth={appState.viewportWidth}
                                            materials={toJS(appState.materials)}
                                            purchased={this.state.isPurchased}
                                            analysis={this.props.analysis}
                                            fieldDefaultValues={toJS(appState.fieldDefaultValues)}
                                            currentValues={{
                                                ...analysis.inputs.asset.properties
                                            }}
                                            submitText="Update analysis"
                                            onSubmit={this.EASY_runChangedParameters}
                                            saveAnalysis={
                                                <SaveResult
                                                    analysis={analysis}
                                                    onSubmit={this.handleSaveCustom}
                                                    saveButtonText={"Save current analysis"}
                                                />
                                            }
                                            revisionsLimit={analysisGroup.length}
                                        />
                                    </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="locationTab">
                                    <div
                                        className="tab-pane fade show location"
                                        id="location"
                                        role="tabpanel"
                                        aria-labelledby="location-tab"
                                    >
                                        <StreetView analysis={analysis} />
                                    </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="varTab">
                                    <div
                                        className="tab-pane fade show var"
                                        id="riskfraction"
                                        role="tabpanel"
                                        aria-labelledby="riskfraction-tab"
                                    >
                                        <ValueAtRisk analysis={analysis} />
                                        <p>
                                            {" "}
                                            These results are based on the values described in the
                                            Breakdown tab.
                                        </p>
                                    </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="breakdownTab">
                                    <div
                                        className="tab-pane fade show risk-fraction breakdown"
                                        id="hazard"
                                        role="tabpanel"
                                        aria-labelledby="hazard-tab"
                                    >
                                        {!this.state.isPurchased && (
                                            <div className="purchase-commercial-inline">
                                                These Value At Risk graph are for illustrative
                                                purposes only, and does not show real data.
                                                <br />
                                                The detailed per-hazard breakdown may only be viewed
                                                after purchasing this analysis.
                                                <br />
                                            </div>
                                        )}

                                        <HazardBreakdown
                                            analysis={analysis}
                                            purchased={this.state.isPurchased}
                                            startYear={analysis.inputs.scenario.startYear}
                                            endYear={analysis.inputs.scenario.endYear}
                                        />

                                        {this.state.isPurchased && (
                                            <div className="protected-zones-warning">
                                                <p>{flood_defence_message}</p>
                                            </div>
                                        )}
                                    </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="vulnerabilityTab">
                                    <div
                                        className="tab-pane fade show vulnerability"
                                        id="vulnerability"
                                        role="tabpanel"
                                        aria-labelledby="vulnerability-tab"
                                    >
                                        {!this.state.isPurchased && (
                                            <div className="purchase-commercial-inline">
                                                This tab displays the relative vulnerability of each
                                                element by hazard. This is an example only, to view
                                                your actual results you must first purchase a
                                                commercial analysis.
                                                <br />
                                            </div>
                                        )}

                                        <Vulnerability
                                            analysis={analysis}
                                            purchased={!!this.state.isPurchased}
                                        />
                                    </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="adaptationsTab">
                                    <div
                                        className="tab-pane fade show adaptations"
                                        id="adaptations"
                                        role="tabpanel"
                                        aria-labelledby="adaptations-tab"
                                    >
                                        <AdaptationWrapper
                                            analysis={analysis}
                                            viewportWidth={appState.viewportWidth}
                                            materials={appState.materials}
                                            handleSaveAdaptation={handleSaveAdaptation}
                                            handleDeleteAdaptation={
                                                handleDeleteAdaptation
                                            }
                                        />
                                    </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="sampleReportTab">
                                    <div
                                        className="tab-pane fade show report"
                                        id="sample"
                                        role="tabpanel"
                                        aria-labelledby="sample-tab"
                                    >
                                        <embed
                                            src="/static/sampleReport/32 Tully St, Carrington, NSW 2294 - 32 Tully St, Carrington, NSW 2294.pdf"
                                            width="100%"
                                            height="800px"
                                        />
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Modal.Body>

                    {viewportWidth <= 799 && <Modal.Footer>{headerFooterButtons}</Modal.Footer>}

                    {loading && (
                        <div className="loading-mask">
                            <Loading />
                        </div>
                    )}

                    <Dialog ref={(el) => (this.confirmSwitchToAnalysisDialog = el)} />
                </Modal>}
            </React.Fragment>
        );
    }
}

export default AnalysisResult;
